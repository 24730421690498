$(function() {

    var slider = $('.range-slider');

    if(slider.length) {
        var range = noUiSlider.create(slider[0], {
            connect: true,
            tooltips: [wNumb({decimals: 0}), wNumb({decimals: 0})],
            //snap: true,
            //behaviour: 'tap',
            start: [ slider.data('start'), slider.data('finish') ],
            range: {
                'min': slider.data('min'),
                'max': slider.data('max')
            },
            pips: { // Show a scale with the slider
                mode: 'values',
                //stepped: true,
                values: slider.data('steps'),
                density: 2
            }
        });

        if(slider.hasClass('js-budget')) {
            range.on('change', function(values) {
                Products.params.price_from = parseInt(values[0]);
                Products.params.price_to = parseInt(values[1]);
                Products.load();
            });
        }
    }


    $('.custom-select').selectize();

});