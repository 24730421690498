$(function() {

    //initialize swiper when document ready
    var slider = new Swiper('.slider .swiper-container', {
        loop: true,
        preloadImages: false,
        // Enable lazy loading
        lazyLoading: true,
        nextButton: '.icon-arrow-right',
        prevButton: '.icon-arrow-left',
        grabCursor: true
    });


    // best sellers
    var best = new Swiper('.slides-best .swiper-container', {
        loop: true,
        preloadImages: false,
        // Enable lazy loading
        lazyLoading: true,
        slidesPerView: 4,
        slidesPerGroup: 4,
        paginationClickable: true,
        pagination: '.slides-best .swiper-pagination',
        spaceBetween: 10,
        grabCursor: true
    });

    window.best = best;

    function updateSlides(carousel) {

        if( carousel.params.slidesPerGroup > 3 && carousel.params.slidesPerView > 3 ) {
            //carousel.params.slidesPerView = 3;
            //carousel.params.slidesPerGroup = 3;
        }

        //carousel.params.slidesPerView = 3;
        //carousel.params.slidesPerGroup = 3;
        //carousel.onResize();
    }

    $(window).on('resize', function() {
        var width = $(window).width();
        if( width < 680 ) {
            updateSlides(best);
        }
    });

    var products = new Swiper('.slides-new .swiper-container', {
        loop: true,
        preloadImages: false,
        // Enable lazy loading
        lazyLoading: true,
        slidesPerView: 4,
        slidesPerGroup: 4,
        paginationClickable: true,
        pagination: '.slides-new .swiper-pagination',
        spaceBetween: 10,
        grabCursor: true
    });

    var productSlidesOptions = {
        //loop: true,
        preloadImages: false,
        // Enable lazy loading
        lazyLoading: true,
        grabCursor: true,
        nextButton: '.product__slides__next',
        prevButton: '.product__slides__prev'
    };


    if($('.product__slides .swiper-slide').length > 1) {
        var productSlides = new Swiper('.product__slides .swiper-container', productSlidesOptions);
    }

    var productThumbsOptions = {
        //loop: true,
        preloadImages: false,
        // Enable lazy loading
        lazyLoading: true,
        grabCursor: true,
        slidesPerView: 7,
        spaceBetween: 3,
        nextButton: '.product__thumbs__button--next',
        prevButton: '.product__thumbs__button--prev'
    };



    var productThumbs = new Swiper('.product__thumbs .swiper-container', productThumbsOptions);


    //productSlides.params.control = productThumbs;
    //productThumbs.params.control = productSlides;

    productSlidesOptions.onSlideChangeStart = function(swiper) {
        //productThumbs.slideTo(swiper.activeIndex);
    };

    productThumbsOptions.onSlideChangeStart = function(swiper) {
        //productSlides.slideTo(swiper.activeIndex);
    };

    productThumbsOptions.onReachEnd = function() {
        console.log('reached');
    };


    var $body = $('body');

    $body.on('click', '.product__thumbs .swiper-slide', function(e) {
        var self = $(this);
        console.log(self.data('swiper-slide-index'));
        self.addClass('current').siblings().removeClass('current');
        //productSlides.slideTo(self.data('swiper-slide-index'));
        e.preventDefault();
    });



});