$(function () {

    var $body = $('body');

    if ($body.hasClass('page-products')) {
        Products.load();
    }

    // toggle filter block
    $body.on('click', '.filter__title', function (e) {
        $(this).closest('.filter').toggleClass('filter--expanded');
        e.preventDefault();
    });

    // toggle children categories
    $body.on('click', '.js-expand-children', function (e) {
        $(this).closest('.has-children').toggleClass('expanded');
        e.preventDefault();
    });

    $body.on('click', '#sort li a', function (e) {
        Products.params.page = 0;
        var self = $(this).closest('li');
        self.addClass('active').siblings().removeClass('active');
        Products.sortBy(self.data('sort'));
        e.preventDefault();
    });

    $body.on('click', '#show-more', function (e) {
        Products.loadMore();
        e.preventDefault();
    });

    // categories filter
    $body.on('click', '#categories li a', function (e) {

        Products.params.page = 0;

        var self = $(this).closest('li');

        if (self.hasClass('has-children')) {
            self.toggleClass('active');

            var checkAll = self.hasClass('active');

            self.find('ul li').each(function (index, element) {

                var self = $(element);

                if (checkAll) {
                    self.addClass('active');
                    Products.addCategory(self.data('id'));
                } else {
                    self.removeClass('active');
                    Products.removeCategory(self.data('id'));
                }


            });
        } else {
            self.toggleClass('active');
            Products.toggleCategory(self.data('id'));
        }


        Products.load();

        e.preventDefault();
    });


    $body.on('click', '#age li a', function (e) {

        Products.params.page = 0;

        var self = $(this).closest('li');

        self.toggleClass('active');
        Products.toggleAge(self.data('id'));

        Products.load();
        e.preventDefault();
    });


    $body.on('change', '#gender input', function (e) {
        Products.params.page = 0;
        Products.setGender($(this).val());
        Products.load();
        e.preventDefault();
    });

    $body.on('change', '#discount input', function (e) {
        Products.params.page = 0;
        Products.setDiscount($(this).val());
        Products.load();
        e.preventDefault();
    });

});


var Products = Products || {};

Products.params['age'] = [];
Products.params['gender'] = 0;
Products.params['discount'] = 0;
Products.params['page'] = 0;
Products.params['categories'] = Products.params.categories || [];

Products.loadMore = function () {
    $('#show-more').find('i').addClass('fa-spin');
    this.params.page += 1;
    this.load(true);
};

Products.load = function (append) {

    append = append ? true : false;

    var productsList = $('#productsList');
    productsList.addClass('is-loading');

    var showMore = $('#show-more');

    var params = {};
    var categories = this.params.categories.slice(0);
    var age = this.params.age.slice(0);

    if (this.params.categories.length) {
        params['categories'] = categories.join(',')
    }

    if (this.params.age.length) {
        params['age'] = age.join(',')
    }

    if (this.params.gender > 0) {
        params['gender'] = this.params.gender;
    }

    if (this.params.discount > 0) {
        params['discount'] = this.params.discount;
    }

    if (this.params.price_from) {
        params['price_from'] = this.params.price_from;
    }

    if (this.params.price_to) {
        params['price_to'] = this.params.price_to;
    }

    if (this.params.sort) {
        params['sort'] = this.params.sort;
    }

    if(this.params.page) {
        params['page'] = this.params.page;
    }

    params['lang'] = this.LANG || 0;

    $.ajax({
        url: this.API_URL,
        method: 'GET',
        data: params,
        success: function (data) {

            // if data exists
            if (data.length) {
                showMore.removeClass('hidden').find('i').removeClass('fa-spin');
                if(append) {
                    productsList.removeClass('is-loading').append(data);
                }
                else {
                    productsList.removeClass('is-loading').html(data);
                }
            } else {
                showMore.addClass('hidden').find('i').removeClass('fa-spin');
            }
        }
    });
};

Products.sortBy = function (sort) {
    this.params.sort = sort;
    this.load();
};

Products.toggleCategory = function (id) {
    this.hasCategory(id) ? this.removeCategory(id) : this.addCategory(id);
};

Products.addCategory = function (id) {
    if (!this.hasCategory(id)) {
        this.params.categories.push(id);
    }
};

Products.hasCategory = function (id) {
    return this.params.categories.indexOf(id) > -1;
};

Products.removeCategory = function (id) {
    this.params.categories.splice(this.params.categories.indexOf(id), 1);
};


Products.toggleAge = function (id) {
    this.hasAge(id) ? this.removeAge(id) : this.addAge(id);
};

Products.addAge = function (id) {
    if (!this.hasAge(id)) {
        this.params.age.push(id);
    }
};

Products.hasAge = function (id) {
    return this.params.age.indexOf(id) > -1;
};

Products.removeAge = function (id) {
    this.params.age.splice(this.params.age.indexOf(id), 1);
};

Products.setGender = function (value) {
    this.params.gender = value;
};

Products.setDiscount = function (value) {
    this.params.discount = value;
};